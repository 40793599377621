import React from "react"

import "./index.scss"

const TripDisclaimer = () => (
  <div className="row">
    <div className="col text-center disclaimer">
      <p>All Bookings require a deposit to confirm your spot</p>
    </div>
  </div>
)

export default TripDisclaimer
