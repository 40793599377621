import React from "react"

const FirstLink = ({ setPage }) => (
  <li className="page-item">
    <button
      type="button"
      className="page-link"
      aria-label="Previous"
      onClick={() => setPage(1)}
    >
      <span aria-hidden="true">&laquo;</span>
    </button>
  </li>
)

export default FirstLink
