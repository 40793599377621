import React from "react"

const LastLink = ({ perPage, total, setPage }) => {
  const totalPages = Math.floor((total - 1) / perPage) + 1

  return (
    <li className="page-item">
      <button
        type="button"
        className="page-link"
        aria-label="Last"
        onClick={() => setPage(totalPages)}
      >
        <span aria-hidden="true">&raquo;</span>
      </button>
    </li>
  )
}

export default LastLink
