import React from "react"

import Icon from "../Icon"
import loader from "./loader.jpg"
import "./index.scss"

const Loader = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-3 d-flex loader-container">
        <div className="loader">
          <img src={loader} alt="" />
          <Icon name="spinner" spin={true} />
        </div>
      </div>
    </div>
  )
}

export default Loader
