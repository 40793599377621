import React from "react"

import FirstLink from "../Pagination/firstLink"
import MiddleLinks from "../Pagination/middleLinks"
import LastLink from "../Pagination/lastLink"

import "./index.scss"

const Pagination = ({ page, perPage, total, setPage }) => {
  if (total <= perPage) {
    return null
  } else {
    return (
      <div className="row">
        <div className="col-12">
          <ul className="pagination justify-content-center">
            <FirstLink setPage={setPage} />
            <MiddleLinks
              page={page}
              perPage={perPage}
              total={total}
              setPage={setPage}
            />
            <LastLink perPage={perPage} total={total} setPage={setPage} />
          </ul>
        </div>
      </div>
    )
  }
}

export default Pagination
